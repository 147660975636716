

import React, { Component } from 'react';

import "./DownloadScreen.css"

import Lottie from 'lottie-react-web'
import confetii from "../../assets/confetti.json"
const INITIAL_STATE = {
  };
  
  
class DownloadScreen extends Component {

constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
}

componentDidMount() {

    // Start loading
    this.setState({ loading: true });

    let isFromApp = localStorage.getItem('isFromApp')

    isFromApp = isFromApp === 'true'

    if (isFromApp) {

      window.analytics.page('Download Page')
    }
    else {

      window.analytics.page('Share Page')
    } 
    
}

buttonPressed = () => {

    let isFromApp = localStorage.getItem('isFromApp')
    isFromApp = isFromApp === 'true'

    let userUid = localStorage.getItem('userUid')

    if (isFromApp) {

      window.analytics.track('Share Button Pressed', {
        user_firebase_id: userUid
      })
    }
    else {

      window.analytics.track('Download Button Pressed', {
        user_firebase_id: userUid,
        from_location: "Download Screen"
      })
    }
}

render () {

  let isFromApp = localStorage.getItem('isFromApp')

  isFromApp = isFromApp === 'true'

    return (

    
    <div className={"DownloadScreen-container"}>
            
            { isFromApp ? <div/> :
           <div className={"DownloadScreen-lottie"}>
              
           <Lottie
              options={{
                animationData: confetii,
                loop: false
              }}
            />
            </div>
          
          }
           

            <img src={"https://i.imgur.com/4X9iXa2.png"} className="DownloadScreen-logo" alt="logo" />

            <p className="DownloadScreen-title">
           You voted!
        </p>

            <p className="DownloadScreen-sub-title">
           {isFromApp ? "Get your friends to vote for your favorite topic before voting ends!" : "Get the app to see which topic wins"}
        </p>

        <button onClick={this.buttonPressed}>
          <div className={"DownloadScreen-button"}>
            <p className={"DownloadScreen-button-text"}>{ isFromApp ? "Get your friends" : "Download the app"}</p>
            </div>
            </button>
    </div>
    )
    
}

}
    
export default DownloadScreen;
    