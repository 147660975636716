import React, { Component } from "react";

import "./SubmitTextInput.css";

import { withFirebase } from "../firebase";
import send from "../../assets/send.png";

const INITIAL_STATE = {
  doneVisible: false
};

class SubmitTextInputBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    // Start loading
    this.setState({ loading: true });
  }

  donePressed = event => {
    event.preventDefault();
    //alert('Your username is: ' + this.input.value);

    if (this.input.value !== null && this.input.value !== "") {
      this.props.donePressed(this.input.value);
    }
  };

  handleChange = event => {
    let shouldShowDone = event.target.value !== "";

    this.setState({ doneVisible: shouldShowDone });
  };

  render() {
    let buttonStyle = this.props.isSelected
      ? "SubmitTextInput-button-selected"
      : "SubmitTextInput-button";

    let inputStyle = this.props.isSelected
      ? "SubmitTextInput-input-selected"
      : "SubmitTextInput-input";

    let doneButtonStyle = this.props.isSelected
      ? "SubmitTextInput-done-selected"
      : "SubmitTextInput-done";

    return (
      <div className={"SubmitTextInput-container"}>
        <form onSubmit={this.donePressed} className={buttonStyle}>
          <input
            type="text"
            name="username"
            placeholder="Submit an idea"
            ref={input => (this.input = input)}
            onChange={this.handleChange}
            className={inputStyle}
          />
          {this.state.doneVisible ? (
            <button type="submit">
              <img src={send} className={"SubmitTextInput-send"} />
            </button>
          ) : (
            <button type="submit">
              <img src={send} className={"SubmitTextInput-send"} />
            </button>
          )}
        </form>
      </div>
    );
  }
}

const SubmitTextInput = withFirebase(SubmitTextInputBase);

export default SubmitTextInput;
