

import React, { Component } from 'react';

import "./LoadingScreen.css"

const INITIAL_STATE = {
  };
  
  
class LoadingScreen extends Component {

constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
}

componentDidMount() {

    // Start loading
    this.setState({ loading: true });

    

}

render () {
    return (

    
    <div className={"LoadingScreen-container"}>
            
            <img src={"https://i.imgur.com/4X9iXa2.png"} className="LoadingScreen-logo" alt="logo" />


            <p className="LoadingScreen-title">
           Loading...
        </p>
    </div>
    )
    
}

}
    
export default LoadingScreen;
    