import React, { Component } from "react";

import "./ChoiceScreen.css";
import ChoiceList from "../../components/choice-list/ChoiceList";

const INITIAL_STATE = {};

class ChoiceScreen extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    // Start loading
    let hasVotedFromApp = localStorage.getItem("hasVotedFromApp");
    hasVotedFromApp = hasVotedFromApp === "true";

    let isFromApp = localStorage.getItem("isFromApp");
    isFromApp = isFromApp === "true";
    this.setState({
      loading: true,
      hasVotedFromApp: hasVotedFromApp,
      isFromApp: isFromApp
    });

    window.analytics.page("Main Page");
  }

  downloadPressed = () => {
    // Open link
    let userUid = localStorage.getItem("userUid");

    window.analytics.track("Gif Button Pressed", {
      user_firebase_id: userUid,
      from_location: "Choice Page"
    });
  };

  voteFinished = isFromApp => {
    if (isFromApp) {
      this.setState({ hasVotedFromApp: true });
    } else {
      this.props.navigateToDownload();
    }
  };

  render() {
    return (
      <div className={"ChoiceScreen-container"}>
        <button onClick={this.downloadPressed}>
          <div className={"ChoiceScreen-header-container"}>
            {this.state.isFromApp ? (
              <div className={"ChoiceScreen-logo-placeholder"} />
            ) : (
              <div className={"ChoiceScreen-logo-container"}>
                <p className="ChoiceScreen-fm-title">TOPIC VOTING</p>
              </div>
            )}

            <p className="ChoiceScreen-title">
              {this.state.hasVotedFromApp
                ? "You voted on next week's topic!"
                : "We make a weekly game based on your"}

              <p className="ChoiceScreen-title-highlighted">
                {this.state.hasVotedFromApp ? "" : " favorite topic"}
              </p>
            </p>
            <img
              src={this.props.gifUrl}
              className="ChoiceScreen-preview"
              alt="logo"
            />
          </div>
        </button>

        <div className={"ChoiceScreen-list"}>
          <ChoiceList voteFinished={this.voteFinished} />
        </div>
      </div>
    );
  }
}

export default ChoiceScreen;
