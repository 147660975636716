import app from "firebase/app"
import 'firebase/database';


var firebaseConfig = {
    apiKey: "AIzaSyB6SMine0FI8FHElvcfuRMcf1RUVWX2L54",
    authDomain: "fad-mania-f3f64.firebaseapp.com",
    databaseURL: "https://fad-mania-f3f64.firebaseio.com",
    projectId: "fad-mania-f3f64",
    storageBucket: "",
    messagingSenderId: "129934426126",
    appId: "1:129934426126:web:947b3fb8b491e29efa7c5f"
  };

  



class Firebase {
    constructor() {
      app.initializeApp(firebaseConfig);
      this.db = app.database();

      if (!localStorage.getItem('userUid')) {


        let randomUid = this.getRandomUid()

        localStorage.setItem('userUid', randomUid)
        
        window.analytics.identify({
          user_firebase_id: randomUid
        })
      }

    }


    loadInitialData = async () => {

      let gameUidSnapshot = await this.db.ref('active-game').once('value')
      let countdownDateSnapshot = await this.db.ref('countdown-date').once('value')
      let gifUrlSnapshot = await this.db.ref('gif-url').once('value')

      this.gameUid = gameUidSnapshot.val()
      this.countdownDate = countdownDateSnapshot.val()
      this.gifUrl = gifUrlSnapshot.val()


    }

    getGameUid = async () => {

      let snapshot = await this.db.ref('active-game').once('value');

      this.gameUid = snapshot.val();
    }

    choices = () => this.db.ref('choices').child(this.gameUid);

    updateVote = (itemUid) => {

      let userUid = localStorage.getItem('userUid')

      if (userUid) {
        
        this.db.ref('votes').child(this.gameUid).child(userUid).set(itemUid)

        window.analytics.track('Choice Recieved Vote', {
          choice_id: itemUid,
          user_id: userUid
        })
      }
    }

    getUserHasVoted = () => {

      let userUid = localStorage.getItem('userUid')

      if (userUid) {

        return this.db.ref('votes').child(this.gameUid).child(userUid)
      }
    }

    createTopic = (value) => {

      let userUid = localStorage.getItem('userUid')

      let newTopicUid = this.getRandomUid()

      if (userUid) {

        let today = new Date()

        let newTopic = {
          uid: newTopicUid,
          createdBy: userUid,
          votes: 0,
          title: value,
          createdAt: today.getTime()
        }

        this.db.ref('votes').child(this.gameUid).child(userUid).set(newTopicUid)
        this.db.ref('choices').child(this.gameUid).child(newTopicUid).set(newTopic)

        window.analytics.track('Choice Created', {
          choice_id: newTopicUid,
          full_name: value,
          user_id: userUid
        })

        window.analytics.track('Choice Recieved Vote', {
          choice_id: newTopicUid,
          user_id: userUid
        })
      }


    }


    getRandomUid = () => {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxAxxxxxxxx".replace(/[xy]/g, function(c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    }
  }
  
  
  
export default Firebase;