import React, { Component } from "react";

// import logo from './logo.svg';
import "./ChoiceList.css";

import ChoiceButton from "../choice-button/ChoiceButton";
import { withFirebase } from "../firebase";
import VoteButton from "../vote-button/VoteButton";
import SubmitTextInput from "../submit-text-input/SubmitTextInput";
import Countdown from "../countdown/Countdown";

//import Countdown from "react-countdown-now"

const INITIAL_STATE = {
  listItems: [],
  selectedChoice: "",
  submissionValue: "",
  countdownDate: "Wed Oct 09 2019 15:19:53 GMT-0700 (Pacific Daylight Time)",
  isFromApp: false
};

const footerStyle = {
  fontSize: "20px",
  color: "white",
  textAlign: "center",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "60px",
  width: "100%"
};

const phantomStyle = {
  display: "block",
  padding: "20px",
  height: "60px",
  width: "100%"
};

function Footer({ children }) {
  return (
    <div>
      <div style={phantomStyle} />
      <div style={footerStyle}>{children}</div>
    </div>
  );
}

class ChoiceListBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  updateVote = () => {
    if (this.state.selectedChoice === "submit") {
      this.props.firebase.createTopic(this.state.submissionValue);
      this.finishUpdatingVote();
    } else if (this.state.selectedChoice !== "") {
      this.props.firebase.updateVote(this.state.selectedChoice);
      this.finishUpdatingVote();
    } else {
      window.analytics.track("Vote Pressed Without Topic");

      alert("Please select a topic");
    }
  };

  finishUpdatingVote = () => {
    localStorage.setItem("hasVotedFromApp", "true");
    this.setState({ hasVotedFromApp: true });
    this.props.voteFinished(this.state.isFromApp);
  };

  updateSelection = itemUid => {
    let hasVotedFromApp = localStorage.getItem("hasVotedFromApp");

    hasVotedFromApp = hasVotedFromApp === "true";

    if (hasVotedFromApp) {
      return;
    }

    if (itemUid !== this.state.selectedChoice) {
      window.analytics.track("Choice Selected", {
        choice_id: itemUid
      });

      this.setState({ selectedChoice: itemUid });
    } else {
      window.analytics.track("Choice Unselected", {
        choice_id: itemUid
      });

      this.setState({ selectedChoice: "" });
    }
  };

  componentDidMount() {
    let hasVotedFromApp = localStorage.getItem("hasVotedFromApp");
    hasVotedFromApp = hasVotedFromApp === "true";

    let isFromApp = localStorage.getItem("isFromApp");
    isFromApp = isFromApp === "true";

    // Start loading
    this.setState({
      loading: true,
      countdownDate: this.props.firebase.countdownDate,
      hasVotedFromApp: hasVotedFromApp,
      isFromApp: isFromApp
    });

    // Get existing choices
    this.props.firebase.choices().on("value", snapshot => {
      const choicesObject = snapshot.val();

      const choicesList = Object.keys(choicesObject).map(key => ({
        ...choicesObject[key],
        uid: key
      }));

      choicesList.sort((a, b) => (a.votes < b.votes ? 1 : -1));

      this.setState({
        listItems: choicesList,
        loading: false
      });
    });
  }

  selectSubmit = value => {
    this.setState({ selectedChoice: "submit", submissionValue: value });

    window.analytics.track("Choice Selected", {
      choice_id: "submit"
    });
  };

  renderCountdown = ({ total }) => {
    return <span className={"ChoiceList-countdown"}>{total}</span>;
  };

  render() {
    let votedId = localStorage.getItem("votedId");

    const listItems = this.state.listItems.map((item, index) => (
      <ChoiceButton
        item={item}
        position={index}
        updateSelection={this.updateSelection}
        isSelected={
          this.state.selectedChoice === item.uid || votedId === item.uid
        }
      />
    ));

    return (
      <div className="ChoiceList-container">
        <p className="ChoiceList-header-text">
          {this.state.hasVotedFromApp
            ? "Voting ends in"
            : "Vote for next week's topic"}
        </p>

        <Countdown date={this.state.countdownDate} />

        {this.state.hasVotedFromApp ? (
          <div />
        ) : (
          <SubmitTextInput
            donePressed={this.selectSubmit}
            isSelected={this.state.selectedChoice === "submit"}
          />
        )}

        <div className="ChoiceList-list-header">
          <div className="rankTopicContainer">
            <div className="rankTitleContainer">
              <p className="ChoiceList-list-header-text">RANK</p>
            </div>
            <div className="rankTitleContainer">
              <p className="ChoiceList-list-header-text">TOPIC</p>
            </div>
          </div>
          <p className="ChoiceList-list-header-text"># VOTES</p>
        </div>

        <ul>{listItems}</ul>

        {this.state.hasVotedFromApp ? (
          <div />
        ) : (
          <Footer>
            <VoteButton
              buttonPressed={this.updateVote}
              canPress={
                this.state.selectedChoice !== "" ||
                this.state.selectedChoice === "submit"
              }
            />
          </Footer>
        )}
      </div>
    );
  }
}

const ChoiceList = withFirebase(ChoiceListBase);

export default ChoiceList;
