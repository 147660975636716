import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import { withFirebase } from "./components/firebase";

import ChoiceScreen from "./screens/choice-screen/ChoiceScreen";
import DownloadScreen from "./screens/download-screen/DownloadScreen";
import LoadingScreen from "./screens/loading-screen/LoadingScreen";

const INITIAL_STATE = {
  inDownloadScreeen: false,
  loading: true
};

class AppBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    // Load has voted

    this.setupInit();

    //let user
    let userUid = localStorage.getItem("userUid");

    window.analytics.identify({
      user_firebase_id: userUid
    });
  }

  getUrlVars = () => {
    var vars = {};
    var parts = window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function(m, key, value) {
        vars[key] = value;
      }
    );
    return vars;
  };

  setupInit = async () => {
    await this.props.firebase.loadInitialData();

    this.setupAnalytics();
    let voteParams = this.getUrlVars();

    localStorage.setItem("isFromApp", voteParams["isFromApp"]);

    localStorage.setItem("hasVotedFromApp", voteParams["hasVotedFromApp"]);

    this.getHasVoted();
  };

  setupAnalytics = () => {
    //   var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
    // analytics.load("qz3wjgcT95TgCrXZmQrW9sJFTdIbyZnO");
    // analytics.page();
    // }
  };

  getHasVoted = () => {
    this.props.firebase.getUserHasVoted().on("value", snapshot => {
      // In download screen if user voted
      let userDidVote = snapshot.val() !== null;
      let voteItemId = snapshot.val();

      if (userDidVote) {
        localStorage.setItem("votedId", voteItemId);
      }

      let hasVotedFromApp = localStorage.getItem("hasVotedFromApp");
      hasVotedFromApp = hasVotedFromApp === "true";

      if (window.ReactNativeWebView && userDidVote) {
        if (hasVotedFromApp) {
          this.setState({ loading: false });
        } else {
          let browserId = localStorage.getItem("userUid");

          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              eventName: "voted",
              browserId: browserId,
              voteItemId: voteItemId
            })
          );
        }
      } else {
        this.setState({ inDownloadScreeen: userDidVote, loading: false });
      }
    });
  };

  navigateToDownload = () => {
    this.setState({ inDownloadScreeen: true });
  };

  render() {
    let hasVotedFromApp = localStorage.getItem("hasVotedFromApp");

    hasVotedFromApp = hasVotedFromApp === "true";

    if (this.state.loading) {
      return (
        <div className="App">
          <LoadingScreen />
        </div>
      );
    } else {
      return (
        <div className="App">
          {/* {
            this.state.inDownloadScreeen && !hasVotedFromApp
            ? 
            <DownloadScreen /> 
            : 
            <ChoiceScreen navigateToDownload={this.navigateToDownload} gifUrl={this.props.firebase.gifUrl}/>
          } */}
          <ChoiceScreen
            navigateToDownload={this.navigateToDownload}
            gifUrl={this.props.firebase.gifUrl}
          />
        </div>
      );
    }
  }
}

const App = withFirebase(AppBase);

export default App;
