import React from "react";
import "./ChoiceButton.css";

function ChoiceButton(props) {
  let buttonStyle = props.isSelected ? "ChoiceButton-selected" : "ChoiceButton";

  let rankContainerStyle = props.isSelected
    ? "rankContainerSelected"
    : "rankContainer";

  let voteTextStyle = props.isSelected
    ? "ChoiceButton-vote-text-selected"
    : "ChoiceButton-vote-text";

  return (
    <button onClick={() => props.updateSelection(props.item.uid)}>
      <div className={buttonStyle}>
        <div className={rankContainerStyle}>
          <p className={"ChoiceButton-number-text"}>{props.position + 1}</p>
        </div>

        <div className={"topicAndVoteContainer"}>
          <p className={"ChoiceButton-text"}>{props.item.title}</p>

          <p className={voteTextStyle}>{props.item.votes}</p>
        </div>
      </div>
    </button>
  );
}

export default ChoiceButton;
