import React, { Component } from "react";

import "./VoteButton.css";

import { withFirebase } from "../firebase";
import check from "../../assets/check.png";

const INITIAL_STATE = {};

class VoteButtonBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    // Start loading
    this.setState({ loading: true });
  }

  render() {
    let buttonStyle = this.props.canPress
      ? "VoteButton-highlighted"
      : "VoteButton";
    return (
      <div className={"VoteButton-container"}>
        <button onClick={this.props.buttonPressed}>
          <div className={buttonStyle}>
            <div className={"VoteButton-circle-fade"}>
              <img src={check} className={"VoteButton-button-icon"} />
            </div>
            <p className={"VoteButton-text"}>Submit Vote</p>
          </div>
        </button>
      </div>
    );
  }
}

const VoteButton = withFirebase(VoteButtonBase);

export default VoteButton;
